import "../style.css";
import logoCabeca from "./logo_cabeca.PNG";
import Formulario from "../formulario";

const Home = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="container">
      <nav className="navbar">
        <div className="nav-content">
          <ul>
            <li><a onClick={() => scrollToSection('inicio')}>Início</a></li>
            <li><a onClick={() => scrollToSection('sobre')}>O que é?</a></li>
            <li><a onClick={() => scrollToSection('formulario')}>Formulário</a></li>
          </ul>
        </div>
      </nav>

      <section id="inicio" className="hero">
        <div className="hero-content">
          <h1>i9Key - DISC</h1>
          <p>Descubra seu perfil comportamental</p>
          <button onClick={() => scrollToSection('formulario')} className="cta-button">
            Começar Avaliação
          </button>
        </div>
      </section>

      <section id="sobre" className="about">
        <div className="about-content">
          <div className="about-text">
            <h2>O que é?</h2>
            <p>
              É uma ferramenta de Mapeamento de Perfil, com base no DISC que
              representa quatro perfis fundamentais no comportamento humano:
              Dominância, Influência, Estabilidade e Conformidade, baseadas nos
              conceitos da teoria de Willian Marston. O propósito do DISC é a
              compreensão dos diferentes padrões comportamentais dos indivíduos.
            </p>
          </div>
          <div className="about-image">
            <img src={logoCabeca} alt="DISC Profile" />
          </div>
        </div>
      </section>

      <section id="formulario" className="form-section">
        <div className="form-content">
          <h2>Avaliação DISC</h2>
          <Formulario />
        </div>
      </section>
    </div>
  );
};

export default Home;
