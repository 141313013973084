import React, { useState, useEffect, useRef } from 'react';
import './index.css';
import axios from 'axios';

const Formulario = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    cpf: '',
    dataNascimento: '',
    empresa: '',
    empresaId: '',
    celular: '',
    cidade: '',
    uf: '',
    cargo: '',
    conexaoCargo: '',
    maiorTalento: '',
  });

  const [disc5Respostas, setDisc5Respostas] = useState({});
  const [disc6Respostas, setDisc6Respostas] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);

  const secondaryItemsRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [disc5Response, disc6Response, empresasResponse] = await Promise.all([
          axios.get("https://api.v2.i9keydiscapi.com.br:9001/disc5", {
            auth: { username: "i9KeyD1sC2024*@_", password: "_iD1sciKey2024@#" },
          }),
          axios.get("https://api.v2.i9keydiscapi.com.br:9001/disc6", {
            auth: { username: "i9KeyD1sC2024*@_", password: "_iD1sciKey2024@#" },
          }),
          axios.get("https://api.v2.i9keydiscapi.com.br:9001/lista/empresa", {
            auth: { username: "i9KeyD1sC2024*@_", password: "_iD1sciKey2024@#" },
          }),
        ]);

        setItems(disc5Response.data);
        setItems2(disc6Response.data);
        setEmpresas(empresasResponse.data);
      } catch (err) {
        console.error("Erro ao buscar dados:", err);
        setError("Erro ao carregar dados. Por favor, tente novamente.");
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    if (errors[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: ''
      }));
    }
  };

  const handleDisc5Change = (itemId, value) => {
    setDisc5Respostas(prev => ({
      ...prev,
      [`disc5_${itemId}`]: value
    }));
    if (errors[`disc5_${itemId}`]) {
      setErrors(prev => ({
        ...prev,
        [`disc5_${itemId}`]: ''
      }));
    }
  };

  const handleDisc6Change = (itemId, value) => {
    setDisc6Respostas(prev => ({
      ...prev,
      [`disc6_${itemId}`]: value
    }));
    if (errors[`disc6_${itemId}`]) {
      setErrors(prev => ({
        ...prev,
        [`disc6_${itemId}`]: ''
      }));
    }
  };

  const validarCPF = (cpf) => {
    if (cpf.length !== 11 || !/^\d+$/.test(cpf)) {
      return false;
    }

    let soma = 0;
    let peso = 10;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf[i]) * peso--;
    }

    let resto = soma % 11;
    if (resto < 2) {
      resto = 0;
    } else {
      resto = 11 - resto;
    }

    if (resto !== parseInt(cpf[9])) {
      return false;
    }

    soma = 0;
    peso = 11;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf[i]) * peso--;
    }

    resto = soma % 11;
    if (resto < 2) {
      resto = 0;
    } else {
      resto = 11 - resto;
    }

    return resto === parseInt(cpf[10]);
  };

  const validateStep = (step) => {
    const newErrors = {};

    switch (step) {
      case 1:
        if (!formData.nome) newErrors.nome = 'Nome é obrigatório';
        if (!formData.email) newErrors.email = 'E-mail é obrigatório';
        else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
          newErrors.email = 'E-mail inválido';
        }
        if (!formData.cpf) newErrors.cpf = 'CPF é obrigatório';
        else if (!validarCPF(formData.cpf)) {
          newErrors.cpf = 'CPF inválido';
        }
        if (!formData.dataNascimento) newErrors.dataNascimento = 'Data de Nascimento é obrigatória';
        if (!formData.empresaId) newErrors.empresa = 'Empresa é obrigatória';
        break;
      case 2:
        if (!formData.celular) newErrors.celular = 'Celular é obrigatório';
        else if (!/^\d+$/.test(formData.celular)) {
          newErrors.celular = 'Celular deve conter apenas números';
        }
        if (!formData.cidade) newErrors.cidade = 'Cidade é obrigatória';
        if (!formData.uf) newErrors.uf = 'UF é obrigatório';
        if (!formData.cargo) newErrors.cargo = 'Cargo é obrigatório';
        if (!formData.conexaoCargo) newErrors.conexaoCargo = 'Conexão com o cargo é obrigatória';
        if (!formData.maiorTalento) newErrors.maiorTalento = 'Maior talento é obrigatório';
        break;
      case 3:
        let firstEmptyDisc5;
        items.forEach((item, index) => {
          if (!disc5Respostas[`disc5_${item.cod}`]) {
            newErrors[`disc5_${item.cod}`] = 'Este campo é obrigatório';
            if (!firstEmptyDisc5) firstEmptyDisc5 = index;
          }
        });
        if (firstEmptyDisc5 !== undefined) {
          setTimeout(() => {
            const element = document.querySelector(`#disc5_${items[firstEmptyDisc5].cod}`);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }, 100);
        }
        break;
      case 4:
        let firstEmptyDisc6;
        items2.forEach((item, index) => {
          if (!disc6Respostas[`disc6_${item.cod}`]) {
            newErrors[`disc6_${item.cod}`] = 'Este campo é obrigatório';
            if (!firstEmptyDisc6) firstEmptyDisc6 = index;
          }
        });
        if (firstEmptyDisc6 !== undefined) {
          setTimeout(() => {
            const element = document.querySelector(`#disc6_${items2[firstEmptyDisc6].cod}`);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }, 100);
        }
        break;
    }

    setErrors(newErrors);
    
    // Se houver erros nos campos normais (não combobox)
    if (Object.keys(newErrors).length > 0 && step <= 2) {
      const firstErrorField = Object.keys(newErrors)[0];
      const element = document.querySelector(`[name="${firstErrorField}"]`) || 
                     document.querySelector(`[id="${firstErrorField}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }

    // Se não houver erros e estiver indo do passo 3 para o 4
    if (Object.keys(newErrors).length === 0 && step === 3) {
      setTimeout(() => {
        if (secondaryItemsRef.current) {
          secondaryItemsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(step)) {
      if (step === 4) {
        onSubmit();
      } else {
        // Se estiver no passo 3, primeiro role para o topo dos comboboxes do passo 4
        if (step === 3) {
          setStep(4);
          setTimeout(() => {
            if (secondaryItemsRef.current) {
              secondaryItemsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 100);
        } else {
          setStep(step + 1);
        }
      }
    }
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      setError("");

      const selectedItems = items.map((item, index) => ({
        id: item.cod,
        descricao: item.descricao,
        selectedValue: disc5Respostas[`disc5_${item.cod}`] === "1" ? "Nada a ver comigo" :
                      disc5Respostas[`disc5_${item.cod}`] === "2" ? "Pouco a ver comigo" :
                      disc5Respostas[`disc5_${item.cod}`] === "3" ? "Mais ou menos a ver comigo" :
                      disc5Respostas[`disc5_${item.cod}`] === "4" ? "Muito a ver comigo" : "",
      }));

      const selectedItems2 = items2.map((item, index) => ({
        id: item.cod,
        descricao: item.descricao,
        selectedValue: disc6Respostas[`disc6_${item.cod}`] === "1" ? "Não precisa se preocupar com isso" :
                      disc6Respostas[`disc6_${item.cod}`] === "2" ? "Já evidência em seu dia a dia" :
                      disc6Respostas[`disc6_${item.cod}`] === "3" ? "Talvez seja interessante desenvolver" :
                      disc6Respostas[`disc6_${item.cod}`] === "4" ? "Precisa desenvolver" : "",
      }));

      const data = {
        name: formData.nome,
        email: formData.email,
        CPF: formData.cpf,
        date: formData.dataNascimento,
        empresa: formData.empresaId,
        celular: formData.celular,
        cidade: formData.cidade,
        uf: formData.uf,
        cargo: formData.cargo,
        cargopapel: formData.conexaoCargo,
        pergunta: formData.maiorTalento,
        items: selectedItems,
        items2: selectedItems2,
      };

      await axios.post(
        "https://api.v2.i9keydiscapi.com.br:9001/insere/formulario",
        data,
        {
          auth: {
            username: "i9KeyD1sC2024*@_",
            password: "_iD1sciKey2024@#",
          },
        }
      );
      
      setSuccess(true);
      setStep(1);
    } catch (err) {
      console.error("Erro ao enviar formulário:", err);
      setError("Erro ao enviar formulário. Por favor, tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="form-step">
            <div className="form-group">
              <label>Nome</label>
              <input
                type="text"
                value={formData.nome}
                onChange={(e) => handleInputChange('nome', e.target.value)}
                placeholder="Digite seu nome"
                className={errors.nome ? 'input-error' : ''}
              />
              {errors.nome && <span className="error-message">{errors.nome}</span>}
            </div>

            <div className="form-group">
              <label>E-mail</label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
                placeholder="Digite seu e-mail"
                className={errors.email ? 'input-error' : ''}
              />
              {errors.email && <span className="error-message">{errors.email}</span>}
            </div>

            <div className="form-group">
              <label>CPF</label>
              <input
                type="text"
                value={formData.cpf}
                onChange={(e) => handleInputChange('cpf', e.target.value)}
                placeholder="Digite seu CPF"
                className={errors.cpf ? 'input-error' : ''}
              />
              {errors.cpf && <span className="error-message">{errors.cpf}</span>}
            </div>

            <div className="form-group">
              <label>Data de Nascimento</label>
              <input
                type="date"
                value={formData.dataNascimento}
                onChange={(e) => handleInputChange('dataNascimento', e.target.value)}
                className={errors.dataNascimento ? 'input-error' : ''}
              />
              {errors.dataNascimento && <span className="error-message">{errors.dataNascimento}</span>}
            </div>

            <div className="form-group">
              <label>Empresa</label>
              <select
                value={formData.empresa}
                onChange={(e) => {
                  const selectedEmpresa = empresas.find(emp => emp.empresa === e.target.value);
                  if (selectedEmpresa) {
                    handleInputChange('empresa', selectedEmpresa.empresa);
                    handleInputChange('empresaId', selectedEmpresa.cod);
                  } else {
                    handleInputChange('empresa', '');
                    handleInputChange('empresaId', '');
                  }
                }}
                className={errors.empresa ? 'select-error' : ''}
              >
                <option value="">Selecione uma empresa</option>
                {empresas.map((empresa) => (
                  <option key={empresa.cod} value={empresa.empresa}>
                    {empresa.empresa}
                  </option>
                ))}
              </select>
              {errors.empresa && <span className="error-message">{errors.empresa}</span>}
            </div>
          </div>
        );

      case 2:
        return (
          <div className="form-step">
            <div className="form-group">
              <label>Celular</label>
              <input
                type="text"
                value={formData.celular}
                onChange={(e) => handleInputChange('celular', e.target.value)}
                placeholder="Digite seu celular"
                className={errors.celular ? 'input-error' : ''}
              />
              {errors.celular && <span className="error-message">{errors.celular}</span>}
            </div>

            <div className="form-group">
              <label>Cidade</label>
              <input
                type="text"
                value={formData.cidade}
                onChange={(e) => handleInputChange('cidade', e.target.value)}
                placeholder="Digite sua cidade"
                className={errors.cidade ? 'input-error' : ''}
              />
              {errors.cidade && <span className="error-message">{errors.cidade}</span>}
            </div>

            <div className="form-group">
              <label>UF</label>
              <select
                value={formData.uf}
                onChange={(e) => handleInputChange('uf', e.target.value)}
                className={errors.uf ? 'select-error' : ''}
              >
                <option value="">Selecione o estado</option>
                {["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", 
                  "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", 
                  "SP", "SE", "TO"].map((uf) => (
                  <option key={uf} value={uf}>{uf}</option>
                ))}
              </select>
              {errors.uf && <span className="error-message">{errors.uf}</span>}
            </div>

            <div className="form-group">
              <label>Cargo</label>
              <input
                type="text"
                value={formData.cargo}
                onChange={(e) => handleInputChange('cargo', e.target.value)}
                placeholder="Digite seu cargo"
                className={errors.cargo ? 'input-error' : ''}
              />
              {errors.cargo && <span className="error-message">{errors.cargo}</span>}
            </div>

            <div className="form-group">
              <label>Conexão com o cargo</label>
              <textarea
                value={formData.conexaoCargo}
                onChange={(e) => handleInputChange('conexaoCargo', e.target.value)}
                placeholder="Digite sua conexão com o cargo"
                className={errors.conexaoCargo ? 'textarea-error' : ''}
              />
              {errors.conexaoCargo && <span className="error-message">{errors.conexaoCargo}</span>}
            </div>

            <div className="form-group">
              <label>Maior talento</label>
              <textarea
                value={formData.maiorTalento}
                onChange={(e) => handleInputChange('maiorTalento', e.target.value)}
                placeholder="Digite seu maior talento"
                className={errors.maiorTalento ? 'textarea-error' : ''}
              />
              {errors.maiorTalento && <span className="error-message">{errors.maiorTalento}</span>}
            </div>
          </div>
        );

      case 3:
        return (
          <div className="form-step">
            <div className="questionnaire-section">
              {items.map((item) => (
                <div key={item.cod} className="question-item">
                  <p className="question-text">{item.descricao}</p>
                  <select
                    value={disc5Respostas[`disc5_${item.cod}`] || ''}
                    onChange={(e) => handleDisc5Change(item.cod, e.target.value)}
                    className={errors[`disc5_${item.cod}`] ? 'select-error' : ''}
                    id={`disc5_${item.cod}`}
                  >
                    <option value="">Selecione uma opção</option>
                    <option value="1">Nada a ver comigo</option>
                    <option value="2">Pouco a ver comigo</option>
                    <option value="3">Mais ou menos a ver comigo</option>
                    <option value="4">Muito a ver comigo</option>
                  </select>
                  {errors[`disc5_${item.cod}`] && (
                    <span className="error-message">{errors[`disc5_${item.cod}`]}</span>
                  )}
                </div>
              ))}
            </div>
          </div>
        );

      case 4:
        return (
          <div className="form-step">
            <div className="questionnaire-section">
              <div ref={secondaryItemsRef} className="secondary-items">
                {items2.map((item2) => (
                  <div key={item2.cod} className="question-item">
                    <p className="question-text">{item2.descricao}</p>
                    <select
                      value={disc6Respostas[`disc6_${item2.cod}`] || ''}
                      onChange={(e) => handleDisc6Change(item2.cod, e.target.value)}
                      className={errors[`disc6_${item2.cod}`] ? 'select-error' : ''}
                      id={`disc6_${item2.cod}`}
                    >
                      <option value="">Selecione uma opção</option>
                      <option value="1">Não precisa se preocupar com isso</option>
                      <option value="2">Já evidência em seu dia a dia</option>
                      <option value="3">Talvez seja interessante desenvolver</option>
                      <option value="4">Precisa desenvolver</option>
                    </select>
                    {errors[`disc6_${item2.cod}`] && (
                      <span className="error-message">{errors[`disc6_${item2.cod}`]}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  if (success) {
    return (
      <div className="success-message">
        <h2>Formulário enviado com sucesso!</h2>
        <p>Obrigado por participar.</p>
      </div>
    );
  }

  return (
    <div className="form-container">
      <div className="progress-bar">
        <div className="progress-step">
          <div className={`step-indicator ${step >= 1 ? 'active' : ''}`}>1</div>
          <span>Dados Pessoais</span>
        </div>
        <div className="progress-step">
          <div className={`step-indicator ${step >= 2 ? 'active' : ''}`}>2</div>
          <span>Informações Profissionais</span>
        </div>
        <div className="progress-step">
          <div className={`step-indicator ${step >= 3 ? 'active' : ''}`}>3</div>
          <span>Questionário 1</span>
        </div>
        <div className="progress-step">
          <div className={`step-indicator ${step >= 4 ? 'active' : ''}`}>4</div>
          <span>Questionário 2</span>
        </div>
      </div>

      <form onSubmit={(e) => e.preventDefault()}>
        {renderStep()}

        {error && <div className="error-message form-error">{error}</div>}

        <div className="form-buttons">
          {step > 1 && (
            <button type="button" onClick={handlePrev} className="prev-button">
              Anterior
            </button>
          )}
          <button
            type="button"
            onClick={handleNext}
            className="next-button"
            disabled={loading}
          >
            {loading ? (
              "Enviando..."
            ) : step === 4 ? (
              "Enviar"
            ) : (
              "Próximo"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Formulario;
